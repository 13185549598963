<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" data-projection-id="1303" style="opacity: 1">
        <div data-testid="hero-video-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <div class="relative w-full h-full overflow-hidden max-h-[80vh] h-[520px] md:h-[650px] md:max-h-full w-full">
            <div autoplay="" style="width: 100%; height: 100%">
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b1.png')" alt="" />
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]">
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1">
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-700 md:text-iblack-300 dark:text-iblack-300 max-w-[500px]">
                Fresh Perspectives
              </h1>
              <p class="text-lg text-iblack-600 md:text-iblack-400 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                Reflections, observations, and analyses
              </p>
              <div class="items-left justify-left mt-8">
                <a draggable="false" 
                  class="inline-flex items-center button-target transition-opacity duration-400 opacity-100 hover:opacity-80"
                  href="javascript:void(0)"><span><svg
                      class="overflow-visible" width="34" height="34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle data-testid="bg-circle" cx="17" cy="17" r="17" fill="#F0F0F0" stroke="#FF0000"
                        stroke-width="0"></circle>
                      <g class="scale-up" clip-path="url(#a)">
                        <path data-testid="stroke-color-path"
                          d="M17.9485 21.5956 22.5441 17l-4.5956-4.5956M22.5441 17H11.5146h11.0295Z" stroke="#4DB96B"
                          stroke-width="1.29983" stroke-linecap="round" stroke-linejoin="round"></path>
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path data-testid="fill-bg-path" fill="#F0F0F0" transform="rotate(-45 23.52081528 1.25735931)"
                            d="M0 0h15.598v15.598H0z"></path>
                        </clipPath>
                      </defs>
                    </svg></span>
                  <span class="text-iblack-700 dark:text-iwhite-100 md:text-iwhite-100 ml-3">Portfolio Portal</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="main-grid">
          <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
            <div role="separator" aria-orientation="horizontal" class="w-full h-full block" data-projection-id="1304"
              style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"></div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x:hidden">
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class=" block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] ">
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class=" block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] ">
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2  relative">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1305">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1306"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b2.png')" alt="" />
                      </div>
                      <div class="
    block
    md:grid md:grid-cols-6 md:gap-4
  ">
                        <div class=" col-span-6 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="1307" style="  transform: translateY(20px) translateZ(0px);">The
                            Essential Tech Stack</h1>
                          <p class="text-iblack-600 mt-2.5" data-projection-id="1308"
                            style="  transform: translateY(20px) translateZ(0px);">The top tools powering
                            modern-day software organizations</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10  relative">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1309">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1310"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b3.png')" alt="" />
                      </div>
                      <div class="
    block
    md:grid md:grid-cols-6 md:gap-4
  ">
                        <div class=" col-span-6 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="1311" style="  transform: translateY(20px) translateZ(0px);">
                            Engineering in a Hybrid World</h1>
                          <p class="text-iblack-600 mt-2.5" data-projection-id="1312"
                            style="  transform: translateY(20px) translateZ(0px);">The data behind
                            high-functioning engineering organizations</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
              <div class="col-span-7 col-start-18  relative">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1313">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1314"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b4.png')" alt="" />
                      </div>
                      <div class="
    block
    md:grid md:grid-cols-6 md:gap-4
  ">
                        <div class=" col-span-6 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="1315" style="  transform: translateY(20px) translateZ(0px);">
                            2022 Topline Growth &amp; Efficiency</h1>
                          <p class="text-iblack-600 mt-2.5" data-projection-id="1316"
                            style="  transform: translateY(20px) translateZ(0px);">The data behind scaling a B2B
                            SaaS business and what strength looks like at all stages of growth</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class=" block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] ">
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2  relative">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1317">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1318"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b5.png')" alt="" />
                      </div>
                      <div class="
    block
    md:grid md:grid-cols-6 md:gap-4
  ">
                        <div class=" col-span-6 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            data-projection-id="1319" style="  transform: translateY(20px) translateZ(0px);">
                            SaaS Path to IPO</h1>
                          <p class="text-iblack-600 mt-2.5" data-projection-id="1320"
                            style="  transform: translateY(20px) translateZ(0px);">A comprehensive study of
                            enterprise SaaS company IPOs and what it takes to successfully plan and execute against this
                            milestone.</p>
                        </div>
                      </div>
                  </section>
                </a></div>
            </div>
            <div class="pb-[30px] md:pb-0 col-span-7 col-start-10  relative">
              <div><a draggable="false" target="_self"
                  class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                  href="/growth/insights">
                  <section class="" data-projection-id="1321">
                    <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1322"
                      style="  transform: translateY(20px) translateZ(0px);">
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b6.png')" alt="" />
                    </div>
                    <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                      <div class=" col-span-6 ">
                        <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          data-projection-id="1323" style="  transform: translateY(20px) translateZ(0px);">The
                          SaaS Glossary</h1>
                        <p class="text-iblack-600 mt-2.5" data-projection-id="1324"
                          style="  transform: translateY(20px) translateZ(0px);">A guide to understanding and
                          tracking key SaaS metrics</p>
                      </div>
                    </div>
                  </section>
                </a></div>
            </div>
            <div class="col-span-7 col-start-18  relative">
              <div><a draggable="false" target="_self"
                  class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                  href="/growth/insights">
                  <section class="" data-projection-id="1325">
                    <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1326"
                      style="  transform: translateY(20px) translateZ(0px);">
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b7.png')" alt="" />
                    </div>
                    <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                      <div class=" col-span-6 ">
                        <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          data-projection-id="1327" style="  transform: translateY(20px) translateZ(0px);">
                          Professional Development</h1>
                        <p class="text-iblack-600 mt-2.5" data-projection-id="1328"
                          style="  transform: translateY(20px) translateZ(0px);">Redefining professional
                          development in a distributed world.</p>
                      </div>
                    </div>
                  </section>
                </a></div>
            </div>
            <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block ">
        <div role="separator" aria-orientation="horizontal" class="w-full h-full block" data-projection-id="1329"
          style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px);"></div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="main-grid--in-grid">
        <div class="w-full relative">
          <div class="flex justify-between items-center mb-30" data-projection-id="1330">
            <div class="md:text-2xl" data-projection-id="1331"
              style="  transform: translateY(20px) translateZ(0px);">Growth &amp; Efficiency Series</div>
            
          </div>
          <div class="w-full relative overflow-hidden select-none" style="height: 409px;">
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(0px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1337">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1338"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b8.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1339" style="  transform: translateY(20px) translateZ(0px);">
                            Growth &amp; Efficiency</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1340"
                            style="  transform: translateY(20px) translateZ(0px);">The data behind scaling a
                            B2B SaaS business and what strength looks like at all stages of growth </p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(629.5px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1341">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1342"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b9.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1343" style="  transform: translateY(20px) translateZ(0px);">
                            The SaaS Glossary</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1344"
                            style="  transform: translateY(20px) translateZ(0px);">A guide to understanding
                            and tracking key SaaS metrics</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block ">
        <div role="separator" aria-orientation="horizontal" class="w-full h-full block" data-projection-id="1349"
          style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px);"></div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="main-grid--in-grid">
        <div class="w-full relative">
          <div class="flex justify-between items-center mb-30" data-projection-id="1350">
            <div class="md:text-2xl" data-projection-id="1351"
              style="  transform: translateY(20px) translateZ(0px);">Future of Work Series</div>
            
          </div>
          <div class="w-full relative overflow-hidden select-none" style="height: 463px;">
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(0px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1357">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1358"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b10.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1359" style="  transform: translateY(20px) translateZ(0px);">
                            Future of Work Series:<br class="hidden md:inline"> Employer Benefits</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1360"
                            style="  transform: translateY(20px) translateZ(0px);">In this report, we explore
                            how technology companies structure their benefits programs and how they are utilizing
                            non-cash incentives as a key lever of total rewards.</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(629.5px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/updates">
                    <section class="" data-projection-id="1361">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1362"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b11.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1363" style="  transform: translateY(20px) translateZ(0px);">
                            The Future of Professional Development:<br class="hidden md:inline"> Part 1</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1364"
                            style="  transform: translateY(20px) translateZ(0px);">Redefining professional
                            development in a distributed world.</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block ">
        <div role="separator" aria-orientation="horizontal" class="w-full h-full block" data-projection-id="1373"
          style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px);"></div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="main-grid--in-grid">
        <div class="w-full relative">
          <div class="flex justify-between items-center mb-30" data-projection-id="1374">
            <div class="md:text-2xl" data-projection-id="1375"
              style="  transform: translateY(20px) translateZ(0px);">Path to IPO Series</div>            
          </div>
          <div class="w-full relative overflow-hidden select-none" style="height: 439px;">
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(0px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1383">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1384"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b12.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1385" style="  transform: translateY(20px) translateZ(0px);">
                            SaaS Path to IPO</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1386"
                            style="  transform: translateY(20px) translateZ(0px);">A comprehensive study of
                            enterprise SaaS company IPOs and what it takes to successfully plan and execute against this
                            milestone.</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(629.5px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1387">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1388"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b13.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1389" style="  transform: translateY(20px) translateZ(0px);">
                            The Path to IPO:<br class="hidden md:inline"> 2022 Healthcare IT Version</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1390"
                            style="  transform: translateY(20px) translateZ(0px);">The most significant
                            drivers of performance at IPO for healthcare IT companies and the impact of current market
                            conditions.</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block ">
        <div role="separator" aria-orientation="horizontal" class="w-full h-full block" data-projection-id="1403"
          style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px);"></div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="main-grid--in-grid">
        <div class="w-full relative">
          <div class="flex justify-between items-center mb-30" data-projection-id="1404">
            <div class="md:text-2xl" data-projection-id="1405"
              style="  transform: translateY(20px) translateZ(0px);">Go-to-Market Series</div>            
          </div>
          <div class="w-full relative overflow-hidden select-none" style="height: 463px;">
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(0px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1413">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1414"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b14.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1415" style="  transform: translateY(20px) translateZ(0px);">
                            Go-to-Market Series:<br class="hidden md:inline"> Introduction</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1416"
                            style="  transform: translateY(20px) translateZ(0px);">An introduction to the
                            Analytics GTM series including an overview of the key challenges faced by organizations and
                            select case studies across sales, marketing, and customer success.</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(629.5px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1417">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1418"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b15.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1419" style="  transform: translateY(20px) translateZ(0px);">
                            Go-to-Market Series:<br class="hidden md:inline"> Processes and Enablers</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1420"
                            style="  transform: translateY(20px) translateZ(0px);">An in-depth look at funnel
                            stages, conversion rates, and the GTM tech stack.
                          </p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block ">
        <div role="separator" aria-orientation="horizontal" class="w-full h-full block" data-projection-id="1433"
          style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px);"></div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="main-grid--in-grid">
        <div class="w-full relative">
          <div class="flex justify-between items-center mb-30" data-projection-id="1434">
            <div class="md:text-2xl" data-projection-id="1435"
              style="  transform: translateY(20px) translateZ(0px);">COVID-19 Series</div>
            
          </div>
          <div class="w-full relative overflow-hidden select-none" style="height: 463px;">
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(0px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1441">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1442"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b16.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1443" style="  transform: translateY(20px) translateZ(0px);">
                            COVID-19 Series:<br class="hidden md:inline"> Impact to Sales Compensation</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1444"
                            style="  transform: translateY(20px) translateZ(0px);">How sales leaders are
                            starting to examine and adjust their compensation strategy in response to the rapidly
                            changing COVID-19 environment.</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(629.5px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1445">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1446"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b17.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1447" style="  transform: translateY(20px) translateZ(0px);">
                            COVID-19 Series:<br class="hidden md:inline"> Scenario Planning</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1448"
                            style="  transform: translateY(20px) translateZ(0px);">Aggregated learnings and
                            best practices across our portfolio related to COVID-19 scenario planning and communication.
                          </p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block ">
        <div role="separator" aria-orientation="horizontal" class="w-full h-full block" data-projection-id="1453"
          style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px);"></div>
      </div>
    </div>
    <div class="main-grid mb-30">
      <div class="main-grid--in-grid">
        <div class="w-full relative">
          <div class="flex justify-between items-center mb-30" data-projection-id="1454">
            <div class="md:text-2xl" data-projection-id="1455"
              style="  transform: translateY(20px) translateZ(0px);">Other Series</div>            
          </div>
          <div class="w-full relative overflow-hidden select-none" style="height: 439px;">
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(0px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1461">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1462"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b18.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1463" style="  transform: translateY(20px) translateZ(0px);">
                            Engineering Efficiency</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1464"
                            style="  transform: translateY(20px) translateZ(0px);">An in-depth look at
                            engineering spend, headcount, productivity, and organizational health metrics.</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
            <div class="absolute left-0 top-0 w-full pr-[16px]" style="width: 629.5px; transform: translateX(629.5px);">
              <div class="h-full">
                <div><a draggable="false" target="_self"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="/growth/insights">
                    <section class="" data-projection-id="1465">
                      <div class="relative overflow-hidden rounded h-[216px] lg:h-[291px]" data-projection-id="1466"
                        style="  transform: translateY(20px) translateZ(0px);">
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/insights/b19.png')" alt="" />
                      </div>
                      <div class="
  block
  md:grid md:grid-cols-6 md:gap-4
">
                        <div class=" col-span-5 ">
                          <h1 class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-xl line-clamp-3"
                            data-projection-id="1467" style="  transform: translateY(20px) translateZ(0px);">
                            2020 Developer Tech Stack Study</h1>
                          <p class="text-iblack-600 mt-2.5 line-clamp-3" data-projection-id="1468"
                            style="  transform: translateY(20px) translateZ(0px);">Key trends across the
                            modern developer tech stack, including what tools are being used by companies at different
                            stages of scale.</p>
                        </div>
                      </div>
                    </section>
                  </a></div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  <footers />
</div></template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
